* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto light', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1.5;
}

.bold {
  font-weight: 700;
  background-color: #EDF4FF;
}


.smooth-scroll {
  scroll-behavior: smooth;
}

input:focus, select:focus, textarea:focus {
  outline: none;
  box-shadow: 0 0 5px #51CBEE;
}

input:invalid, select:invalid, textarea:invalid {
  outline: none;
  box-shadow: 0 0 5px #FF4646;
}

::placeholder {
  color: #c4c4c4;
}

a {
  text-decoration: none;
}

.link, .active-nav {
  color: #54596e;
  font-weight: bold;
}

.inactive-nav {
  color: #888888;
}

.incorrect-field {
  color: red;
  font-size: 12px;
  margin: 0 auto;
  text-align: center;
}

.valid-field {
  color: #32d974;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
}

Link {
  text-decoration: none;
}

body{
  color: #888888;
  font-size: 13px;
}

.org-tag, .join-tag, .cancel-tag, .res-tag {
  color: #fff;
  padding: 3px 10px;
  font-size: 90%;
  display: inline-block;
  text-align: center;
	outline: 0;
	border: 1px solid transparent;
	border-radius: 5px;
}

.org-tag {
  background-color: #3d98ff;
}

.res-tag {
  background-color: #1bc938;
}

.join-tag {
  background-color: #51F26C;
}

.cancel-tag {
  background-color: #ff4646;
}

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.preloader img {
  width: 75px;
  height: 75px;
}

/*[RADIO BUTTON]*/

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #F87DA9;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

/*[BUTTON]*/

.btn-one, .btn-two, .btn-three, .btn-four, .btn-five, .upload-btn {
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	padding: 10px 20px;
  display: inline-block;
  text-align: center;
	outline: 0;
	border: 1px solid transparent;
	border-radius: 5px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.btn-one, .upload-btn{
	background-color: #E14658;
}

.btn-one:hover, .upload-btn:hover {
	color: #e14658;
	background-color: #fff;
  border-color: #e14658;
  cursor: pointer;
}

.btn-two{
	background-color: #007BB5;
}

.btn-two:hover {
	color: #007BB5;
	background-color: #fff;
  border-color: #007BB5;
  cursor: pointer;
}

.btn-three{
	background-color: #888888;
}

.btn-three:hover {
	color: #888888;
	background-color: #fff;
  border-color: #888888;
  cursor: pointer;
}

.btn-four{
  color: #fff;
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}

.btn-four:hover {
	color: #c4c4c4;
	background-color: #fff;
  border-color: #c4c4c4;
  cursor: pointer;
}

.btn-five{
  color: #e14658;
  background-color: #fff;
  border-color: #e14658;
}

.btn-five:hover {
	color: #fff;
	background-color: #e14658;
  border-color: #e14658;
  cursor: pointer;
}

.btn-inactive {
  color: #fff;
  background-color: #c4c4c4;
	font-size: 14px;
	font-weight: 600;
	padding: 10px 20px;
  display: inline-block;
  text-align: center;
	outline: 0;
	border: 1px solid transparent;
	border-radius: 5px;
}

.btn-spacing {
  margin-top: 10px;
}


button {
  border: none;
}

.exit {
  position: absolute;
  top: 15px;
  right: 15px;
}

.exit img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/*[NAVBAR]*/

.nav-page {
  display: flex;
  border-bottom: 1px solid #c4c4c4;
  justify-content: center;
}

.nav-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding: 0 20px;
  width: 100%;
  height: 75px;
}

.nav-left img {
  width: 50px;
  height: 50px;
}

.nav-mobile img {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.nav-full {
  display: flex;
  justify-content: center;
}

.nav-links-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links {
  color: #40464B;
  display: flex;
  align-items: center;
  margin: 0 20px;
  height: 75px;
  cursor: pointer;
}

.nav-links:hover {
  color: #286da8;
}

.nav-profile {
  display: flex;
  align-items: center;
  margin-left: 20px;
  position: relative;
  height: 75px;
}

.nav-profile img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.nav-settings {
  position: absolute;
  right: -20px;
  top: 76px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  padding: 0 20px;
  background: #fff;
  width: 150px;
  z-index: 1;
}

.nav-settings li {
  display: flex;
  list-style: none;
  border-bottom: 1px solid #c4c4c4;
  font-size: 16px;
  color: #40464B;
  height: 30px;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;
}

.nav-settings li:last-of-type {
  border-bottom: none;
}

.nav-settings li:hover, .nav-links:hover {
  border-bottom: 2px solid #c4c4c4;
  color: #286da8;
}

.nav-mobile-links:hover {
  font-weight: bold;
}

.nav-mobile-menu {
  position: absolute;
  top: 75px;
  right: 0;
  width: 300px;
  border: 1px solid #c4c4c4;
  border-right: 0;
  background-color: #fff;
  padding: 20px;
  z-index: 2;
}

.nav-mobile-menu:focus {
  background: #000;
}

.nav-mobile-links {
  color: #40464B;
  height: 40px;
}

.nav-mobile-links:hover {
  color: #286da8;
  height: 40px;
}

.nav-prelogin-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  z-index: 1;
}

.nav-landing-page {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 1;
}

.nav-prelogin-area {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 83%;
}

.nav-prelogin-left {
  display: flex;
  align-items: center;
}

.nav-prelogin-left img{
  width: 200px;
}

.nav-prelogin-right {
  display: flex;
  align-items: center;
}

/*
.nav-prelogin-links {
  font-size: 14px;
  color: #40464B;
  display: flex;
  align-items: center;
  margin: 0 20px;
  cursor: pointer;
}


.nav-prelogin-links:hover {
  color: #888888;
}*/

.btn-login {
  color: #e14658;
  font-size: 13px;
  font-weight: 600;
  padding: 8px 20px;
  display: inline-block;
  text-align: center;
  border: 1px solid #e14658;
  border-radius: 40px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-left: 20px;
}

.btn-login:hover {
	color: #fff;
	background-color: #e14658;
  border-color: transparent;
  cursor: pointer;
}

/*[LANDING PAGE]*/

.landing-page {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  scroll-behavior: smooth;
}

.join-now-section {
  position: relative;
  width: 100%;
  height: 100vh;
}

.join-now-section img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.join-now-content {
  position: absolute;
  max-width: 500px;
  top: 30%;
  left: 10%;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
}

.join-now-header {
  font-size: 36px;
  color: #40464B;
  font-weight: bold;
}

.join-now-subheader {
  font-size: 28px;
  color: #40464B;
  font-weight: bold;
}

.join-now-body {
  font-size: 16px;
  color: #888888;
  margin: 30px 0;
}

.join-now-btn {
  display: flex;
  width: 75%;
  justify-content: center;
}

.how-it-works-section, .featured-listings-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: linear-gradient(#fff 15%, #fafbfd, #fff 85%);
}

.hiw-section-header, .fl-section-header {
  font-size: 36px;
  color: #40464B;
  margin-top: 20px;
}

.hiw-section-subheader, .fl-section-subheader {
  font-size: 18px;
  color: #888888;
  text-align: center;
}


.hiw-container, .fl-container {
  width: 90%;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  justify-content: space-evenly;
}

.hiw-content {
  width: 90%;
  max-width: 350px;
  display: flex;
  margin: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 5px 5px 15px;
}

.hiw-content-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.hiw-content-icon img {
  width: 150px;
  height: 150px;
}

.hiw-content-header {
  font-size: 22px;
  color: #40464B;
  text-align: center;
}

.hiw-content-body {
  margin: 20px;
  font-size: 15px;
  text-align: center;
}

.purpose-section {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.purpose-text {
  margin: 60px;
  font-size: 40px;
  text-align: center;
  color: #192057
}


.fl-content {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  margin: 20px;
  box-shadow: 0 1px #FFFFFF inset, 0 1px 3px rgba(34, 25, 25, 0.4);
  background-color: #fff;
}

.fl-content:hover  {
  box-shadow: 10px 10px 30px #c4c4c4;
  -webkit-transition: 0.3s;
	-o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.fl-content-img-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.fl-content-img-container img {
  width: 100%;
  object-fit: cover;
}

.fl-content:hover .fl-content-img-container img {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
      transform: scale(1.1);
}

.fl-event-label {
  border-radius: 5px;
  width: fit-content;
  margin: 20px 20px 10px 20px;
  padding: 5px 15px;
  font-size: 22px;
  color: #fff;
}

.fl-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.fl-details, .fl-details-link, .fl-details-spots-left {
  color: #40464B;
  margin: 5px 20px;
  font-size: 14px;
  font-weight: 500;
}

.fl-spots-left {
  display: flex;
  align-items: center;
}

.fl-spots-left img{
  width: 15px;
}

.fl-details-spots-left {
  color: #e14658;
  margin-right: 10px;
  font-weight: 700;
}


.fl-details-link {
  color: #54596e;
  font-weight: 700;
}


.fl-content:hover .fl-details-link{
  text-decoration: underline;
}

.testimonial-section {
  display: flex;
  flex-direction: column;
  flex-direction: center;
  align-items: center;
  width: 100%;
}

.testimonial-section-header {
  font-size: 36px;
  color: #40464B;
  margin-top: 20px;
}

.testimonial-section-subheader {
  font-size: 18px;
  color: #888888;
  text-align: center;
}

.testimonial-ratings {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.testimonial-ratings-graphic {
  margin: 0 25px;
}

.testimonial-ratings-graphic img{
  width: 25px;
  margin-right: 5px;
}


.testimonial-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 90%;
  margin: 20px;
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  padding: 20px;
}

.testimonial-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 250px;
  color: #888888;
  font-size: 16px;
  font-style: italic;
}

.testimonial-signature {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  font-weight: 500px;
  margin: 20px;
}

.testimonial-signature-name {
  font-size: 18px;
  color: #40464B
}

.testimonial-signature-body {
  font-size: 14px;
  color: #c4c4c4
}

.video-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url('../images/bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

.video-section-header {
  font-size: 36px;
  color: #40464B;
  margin-top: 20px;
}

.video-section-subheader {
  font-size: 18px;
  color: #888888;
  text-align: center;
}

.video-container-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 20px;
  margin: 20px;
}

.video-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  max-width: 1000px;
}

.video-text {
  display: flex;
  width: 350px;
  margin: 20px;
  text-align: center;
  font-size: 16px;
  color: #54596e
}

.video-embed {
  display: flex;
  min-width: 350px;
  border-radius: 20px;
  overflow: hidden;
  margin: 15px;
}

.why-share-accommodations-section {
  position: relative;
  width: 100%;
  height: 170vh;
  background-image: url('../images/whyShareBg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.why-container {
  position: absolute;
  max-width: 500px;
  top: 10%;
  right: 10%;
  background-color: #fff;
  width: 80%;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 5px 5px 15px;
}

.why-content-header {
  font-size: 28px;
  font-weight: 500;
  color: #40464B;
}


.why-content-subheader {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: 500;
  color: #40464B;
}

.why-content-subheader img {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.why-content-body {
  margin-top: 10px;
  font-weight: 300;
  font-size: 15px;
}

.why-content-item {
  margin: 20px 0;
}


.contact-us-section {
  margin: 50px 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-us-header {
  font-size: 14px;
}

.contact-us-subheader {
  color: #40464B;
  font-size: 30px;
  text-align: center;
}

.contact-us-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  width: 100%;
  max-width: 700px;
}

.contact-us-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}


.contact-us-row input[type='text'], .contact-us-row textarea{
  background-color: #fff;
  border: 1px solid #d6d9dc;
  border-radius: 7px;
  width: 100%;
  flex: 1;
  padding: 15px;
  margin: 10px;
  font-size: 14px;
  color: #888888;
  resize: none;
}

.contact-us-row textarea {
  height: 200px;
}

.contact-us-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.landing-footer-section {
  background-color: #192057;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 30vh;
  margin-top: 30px;
}

.landing-footer-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}


.landing-footer-about {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 500px;
  margin: 20px;
}

.landing-footer-about img{
  width: 50%;
}

.landing-footer-about-body {
  margin: 10px;
  font-size: 13px;
  color: #c4c4c4;
}

.landing-footer-links {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #fff;
  margin: 20px;
  margin-right: 50px;
}

.landing-footer-links a {
  font-size: 13px;
  margin-top: 10px;
  color: #c4c4c4;
}

.landing-footer-links a:hover {
  color: #fff;
}


.landing-footer-contact {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #fff;
  margin: 20px;
}

.landing-footer-contact a, .landing-footer-email {
  font-size: 13px;
  margin-top: 10px;
  color: #c4c4c4;
}

.privacy-tos-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.privacy-tos-section {
  max-width: 1000px;
  width: 90%;
}

.privacy-tos-section li{
  display: list-item;
  color: #888888;
  list-style: disc;
  margin-left: 30px;
}


/*[LOGIN]*/


.log-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 80vh;
}

.log-content {
  Display: flex;
  flex-direction: column;
  max-width: 350px;
  width: 80%;
}

.log-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
}

.log-logo img {
  width: 40%
}

.log-header {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
  line-height: 1.3;
}

.log-header-regular {
  font-size: 20px;
  text-align: center;
  margin: 20px 0;
  line-height: 1.3;
}

.log-subheader {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
  line-height: 1.3;
}

.log-subheader-text {
  font-size: 14px;
  margin: 10px 0;
}

.log-field-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.log-input-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  width: 100%;
  height: 40px;
}

.log-navigation {
  width: 35px;
  margin: 10px;
  cursor: pointer;
}

.log-phone-input, .log-input-container input[type='text'], .log-input-container input[type='password'], .log-input-container select,  .log-input-month-container select, .log-input-day-container select, .log-input-year-container select {
  background-color:#fff;
  border: 1px solid #d6d9dc;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  padding: 20px;
  font-size: 14px;
  color: #888888;
  resize: none;
}

.react-phone-number-input__row input[type='tel']{
  color: #888888;
  border: none;
  box-shadow: none;
}

.log-phone-input {
  display: flex;
  align-items: center;
  text-underline-position: none;
}

.log-select-icon, .se-select-icon, .cl-select-icon,
.ld-select-icon {
  position: absolute;
  right: 10px;
  top: 20%;
  color: #c4c4c4;
  font-size: 16px;
  pointer-events: none;
}

.log-input-container select, .log-input-month-container select, .log-input-day-container select, .log-input-year-container select {
  padding: 0px 20px;
}

.log-input-month-container {
  position: relative;
  width: 45%;
}

.log-input-day-container {
  position: relative;
  width: 25%;
  Height: 40px;
  margin-left: 15px;
}

.log-input-year-container {
  position: relative;
  width: 30%;
  Height: 40px;
  margin-left: 15px;
}

.log-signup-section {
  margin-top: 20px;
}

.log-birthday-section {
  display: flex;
  justify-content: space-evenly;
}

.log-btn, .log-send-link-btn {
  display: flex;
  width: 100px;
  align-self: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
}

.log-send-link-btn {
  width: 120px;
  margin-top: 15px;
}

.log-footer-area {
  margin-bottom: 50px;
}

.log-footer {
  font-size: 14px;
  align-self: center;
  text-align: center;
  margin-top: 20px;
}

.log-footer-item {
  margin: 5px 0;
}


/*[EVENTS PAGE]*/

.ev-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
}

.ev-section {
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
}

.ev-section-header {
  display: flex;
  align-items: center;
  font-size: 28px;
  color: #40464B;
  font-weight: 500px;
  padding: 10px 0;
  padding-left: 40px;
  border-bottom: 1px solid #c4c4c4;
}

.ev-section-header img {
  height: 25px;
  width: 25px;
  margin: 0 15px;
}

.ev-section-content {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 20px;
}

.ev-card-container {
  position: relative;
  width: 300px;
  height: 225px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 20px 30px 20px;
  box-shadow: 5px 5px 15px #c4c4c4;
}

.ev-card-container:hover {
  box-shadow: 10px 10px 30px #c4c4c4;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.ev-card-container:hover img {
  -webkit-transition: 0.5s;
	-o-transition: 0.5s;
  transition: 0.5s;
	-webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
      transform: scale(1.1);
}

.ev-card-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.ev-card-img img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}

.ev-card-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}

.ev-card-title {
  font-size: 18px;
}

/*[EXCHANGE / HEADER AREA]*/

.ex-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  color: #40464B;
  padding: 20px;
  margin: 10px 0px 0px 0px;
  border-bottom: 1px solid #c4c4c4;
}

.ex-event-details {
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.ex-event, .ex-date, .ex-location {
  margin-right: 40px;
  line-height: 20px;
}

.ex-event {
  font-size: 34px;
  line-height: 1.3;
  font-weight: 500px;

}

.ex-date, .ex-location {
  display: flex;
  align-items: center;
  font-size: 18px;
  max-width: 200px;
}

/*[EXCHANGE / SECTIONS AREA]*/

.ex-map-img {
  margin-right: 20px;
  overflow: hidden;
  object-fit: cover
}

.exchange {
  display: flex;
  justify-content: flex-start;
}

.ex-listing {
  flex: initial;
  width: 700px;
  min-width: 350px;
  height: 80vh;
  padding: 10px 20px 0px 20px;
  overflow: auto;
}

.ex-map {
  flex: 1;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

/*[EXCHANGE / INDIVIDUAL LISTING AREA]*/

.ex-no-listing-container {
  display: flex;
  border: 1px solid #e5e5e5;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.ex-no-listing-header {
  color: #888888;
  font-size: 16px;
  margin-bottom: 10px;
}

.ex-no-listing-subheader {
  color: #888888;
  margin-bottom: 20px;
  font-size: 18px;
}

.idv-listing {
  display: flex;
  justify-content: flex-start;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px;
  margin: 20px 0;
}

.ex-listing-content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 200px);
  max-height: 150px;
  padding: 0 10px;
}

.ex-listing-img {
  display: flex;
  position: relative;
  flex: initial;
  border-radius: 5px;
  object-fit: cover;
  overflow: hidden;
  height: 150px;
  width: 200px;
  justify-content: center;
  align-items: center;
}

.ex-listing-img-area, .ex-listing-img-area img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.ex-listing-title, .ex-listing-date-price {
  color: #40464B;
}

.ex-listing-title {
  font-size: 18px;
  line-height: 1;
  margin: 10px 10px 10px 0;
  height: 20px;
  font-weight: 500px;
}

.ex-listing-description {
  position: relative;
  color: #888888;
  font-size: 13px;
  line-height: 1.5;
  height: 60px;
  margin: 0 10px 10px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ex-listing-description-fade {
  position: absolute;
  top: 0;
  right: 0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0),#fff);
  width: 100%;
  height: 100%;
}

.ex-listing-footer{
  color: #40464B;
  margin-top: auto;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ex-listing-actions {
  font-size: 12px;
  margin: 0 10px;
  display: inline;
  align-self: flex-end;
}

.ex-listing-date-price {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  text-align: right;
  margin-right: 10px;
}

.ex-listing-date, .ex-listing-price, .ex-listing-actions, .ex-listing-title, .ex-avatar-name, .ex-avatar-location, .od-guest-name, .od-guest-location {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ex-avatar {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  bottom: 0;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0 0 5px 5px;
}

.ex-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.ex-modal-details {
  margin: 0px 10px;
  color: #40464B;
}

.ex-avatar-name, .ex-avatar-location {
  color: #fff;
}

.ex-avatar-name {
  width: 100%;
}

.ex-avatar-location {
  font-size: 80%;
}

.ex-avatar-img-area {
  display: flex;
  flex: initial;
	width: 40px;
  height: 40px;
  margin: 10px;
}

.ex-avatar-img-area img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.ex-avatar-details {
  width: 60%;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 10px;
}

.idv-listing:hover  {
  box-shadow: 10px 10px 30px #c4c4c4;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.idv-listing:hover .ex-listing-img-area img{
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
  transition: 0.5s;
	-webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
      transform: scale(1.1);
}

/*[LISTING DETAILS]*/


.ld-page, .od-page {
  width: 90%;
  max-width: 960px;
  margin: 0 auto;
  margin-top: 100px;
}

.ld-container {
  display: flex;
  flex-direction: column;
  max-width: 575px;
}

.ld-title, .ld-date, .ld-location {
  color: #40464B
}

.ld-title {
  font-size: 36px;
  line-height: 1;
  font-weight: 500px;
}

.ld-title img {
  width: 30px;
  margin: 0 10px;
}


.ld-subtitle {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 1.5;
  max-width: 400px;
  margin-top: 10px;
}

.ld-date {
  margin-right: 20px
}

.ld-avatar-area {
  display: flex;
  justify-content: flex-start;
  margin: 10px;
  margin-top: 20px;
  text-decoration: none;
}

.ld-avatar-details, .od-guest-details {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin-left: 20px;
}

.ld-avatar-img, .od-guest-img {
	width: 50px;
  height: 50px;
  flex: initial;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.ld-avatar-name, .od-guest-name{
  font-weight: 400px;
  margin-bottom: 2px;
  font-size: 14px;
  color: #40464B;
}

.ld-avatar-location, .od-guest-location {
  font-size: 95%;
  color: #40464B;
}

.ld-description {
  font-size: 13px;
  margin: 20px 0;
  height: 100%;
  overflow-wrap: break-word;
  white-space: pre-line;
}

.ld-description textarea{
  background-color: #fff;
  border: 1px solid #d6d9dc;
  border-radius: 3px;
  width: 100%;
  padding: 7px;
  font-size: 14px;
  color: #888888;
  resize: none;
  min-height: 200px;
}

.ld-img-section {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.ld-img {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 0 0;
}

.ld-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ld-map {
  width: 100%;
  margin: 20px 0;
}

.ld-map-container {
  height: 50vh;
	width: 100%;
  position: relative;
}

.ld-sidebar {
  display: flex;
  flex-direction: column;
}
.ld-request-container, .ld-cancel-container {
  max-width: 375px;
  display: flex;
  margin: 0 20px;
  flex-direction: column;
  border: 1px solid #c4c4c4;
}

.ld-listing-full-message { 

  display: flex;
  justify-content: center;
  padding: 40px 0px 20px 0px;
  
}

.ld-cancel-container {
  margin-top: 30px;
}

.ld-cancel-header {
  font-size: 18px;
  padding: 10px 20px;
  border-bottom: 1px solid #c4c4c4
}

.ld-cancel-text, .ld-standard-cancel-text {
  font-size: 12px;
  padding: 10px 20px;
  text-align: center;
  margin-bottom: 10px;
}

.ld-standard-cancel-text {
  width: 80%;
  border: 1px solid #c4c4c4;
  align-self: center;
  background-color: #f5f2f2;
  margin-top: 10px;
  border-radius: 5px;
}

.ld-price {
  font-size: 18px;
  color: #40464B;
  padding: 20px;
  justify-content: flex-start;
  border-bottom: 1px solid #c4c4c4
}

.ld-guest-selection {
  position: relative;
  display: flex;
  margin: 20px;
  margin-bottom: 10px;
  justify-content: left;
}

.ld-select-icon {
  top: 10%;
}

.ld-guest-selection select {
  width: 100%;
  font-size: 13px;
  color: #888888;
  text-align: left;
  border-radius: 3px;
  padding-left: 10px;
  height: 30px;
}

.ld-receipt {
  margin: 0 20px;
}

.ld-receipt-row {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
}

.ld-btn-container {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.ld-btn {
  width: 150px;
}

.ld-request-none {
  padding: 10px 20px;
}



/*[DASHBOARD - ALL]*/

.dash-sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
}

.trip-status, .trip-manage {
  display: flex;
  margin: 0 20px;
  margin-bottom: 30px;
  flex-direction: column;
  border: 1px solid #c4c4c4;
}

.dash-header {
  display: flex;
  justify-content: space-between;
}

.dash-dates {
  width: 50%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #c4c4c4;
}

.dash-text {
  font-size: 20px;
  padding: 2px;
}

.trip-info {
  padding: 20px;
}

.trip-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.trip-info-footer {
  display: flex;
  text-align: center;
  padding: 10px;
}

.trip-manage-header { 
  font-size: 20px;
  padding: 20px;
  justify-content: flex-start;
  border-bottom: 1px solid #c4c4c4
}

.trip-manage-area {
  display: flex;
  flex-direction: column;
}

.trip-manage-msg, .trip-manage-fn {
  padding: 10px;
  text-align: center;
}

.trip-manage-msg-title {
  text-align: center;
  margin-top: 10px;
}
.trip-manage-fn {
  font-size: 80%;
}

.request-cancel {
  align-self: center;
  max-width: 200px;
  margin: 10px
}

.request-cancel-2 {
  align-self: center;
  max-width: 200px;
  margin-bottom: 20px
}

/*[DASHBOARD - ORGANIZER]*/

.od-page {
  display: flex;
  flex-direction: column;
}

.od-edit-container {
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin: 10px 0 10px;
}

.od-edit-btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.od-manage-request {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
}

.od-manage-request:hover {
  box-shadow: 5px 5px 15px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
  transition: 0.3s;
}

.od-guest-top, .od-guest-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.od-guest-avatar {
  display: flex;
  justify-content: flex-start;
  width: calc(100% - 100px);
}

.od-guest-status{
  display: flex;
  justify-content: center;
  align-items: center;
}

.join-tag, .cancel-tag {
  min-width: 75px;
}

.od-guest-action {
  display: flex;
  justify-content: space-around;
}


.od-guest-cancel-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  font-size: 12px;
}


.od-action-btn {
  height: 25px;
  width: 25px;
  border-radius: 50px;
  margin: 0 10px;
  fill: #fff;
}

.od-cancel-page {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.od-cancel-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 250px;
  width: 300px;
  background-color: #fff;
  border-radius: 5px;
}

.od-cancel-title {
  line-height: 1.2;
  font-size: 20px;
  text-align: center;
  padding: 10px;
}

.od-cancel-text {
  line-height: 1.2;
  font-size: 13px;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}

.od-cancel-text {
  line-height: 1.2;
  font-size: 13px;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}


.od-cancel-btn-row {
  display: flex;
  justify-content: space-evenly;
}


/*[CREATE LISTING]*/
.cl-imgPreviewWrapper{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.cl-imgPreviewContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 100px;
  margin: 8px 8px 8px 8px;  
}

.cl-imgPreviewContainer img{
  object-fit: cover;
}

.cl-imgPreview {
  display: block;
  width: 100px;
  height: 80px;
  border: 3px solid #eaeaea;
}

.cl-imgPreview-delete {
  position: absolute;
  top: 0;
  right: 0;
}

.cl-guest-counter {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  
  position: relative;
}
.cl-guest-num {
  height: 100%;
  width: 50px;
  text-align: center;
  vertical-align: middle;
}

.cl-step1, .cl-step2 {
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin: 0 auto;
  margin-top: 50px;
  font-size: 18px;
}

.pay-step1-side {
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin: 0 auto;
  margin-top: 50px;
  font-size: 18px;
}


.pay-step1-middle {
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin: 0 auto;
  margin-top: 50px;
  font-size: 18px;
}

.cl-field, .cl-cancelpol {
  position: relative;
  margin: 20px;
  max-width: 500px;
}

.cl-select-icon {
  top: 52%;
}

.cl-title-field {
  width: 100%;
  padding: 20px 20px 0 20px;
  max-width: 500px
}

.cl-datePicker-wrapper{
  display: flex;
  flex-direction: column;
}

.cl-datePicker-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  font-size: 15px;
}

.cl-datePicker-footer-btn {
  outline: none;
  border: none;
  background-color: transparent;
  color: #54596e;
  font-weight: bold;
}

.cl-datePicker-footer-btn:hover {
  text-decoration: underline;
  cursor: pointer;
}

.cl-datePicker-footer-btn:focus {
  outline: none;
}

.cl-datePicker {
  margin-top: 20px;
  display: none;
  border-radius: 3px;
  position: absolute;
  background-color: #f5f2f2;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
  border-bottom: 0 none;
  /* margin-top: 110px; */
  z-index: 999;
  
  /* border-radius: 3px; */
  /* border: 1px solid black;   */
}

.calendarToggle {
  background-color: #fff;
  border: 1px solid #d6d9dc;
  border-radius: 3px;
  width: 100%;
  padding: 7px;
  font-size: 14px;
  color: #888888;
  height: 37px;  
}

.calendarToggle:active, .calendarToggle:focus {
  outline: none;
  
}

.btnDateText {
  display: flex;
  flex-direction: row;
  justify-content: space-around
}


.help-filter {
  filter: invert(61%) sepia(0%) saturate(986%) hue-rotate(161deg) brightness(88%) contrast(99%);
}

.cl-cancelpol-label {
  display:flex;
  flex-direction: column;
  
}

.cl-cancelpol-text {
  font-size: 13px;
  margin: 10px 0;
}

.cl-description {
  margin: 20px;
}

.cl-field label, .cl-title-field label, .cl-description label, .cl-cancelpol label, .cl-label {
  color: #40464B;
  font-size: 18px;
  font-weight: 500px;
}

.cl-subLabel {
  color: rgb(133, 140, 145);
  font-size: 13px;
  font-weight: 400px;
}

.cl-map-legend {
  color: rgb(133, 140, 145);
  font-size: 13px;
  font-weight: 400px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.cl-map-legend-description {
  margin-top: 5px;
}



.cl-field input[type='text'], .cl-title-field input[type='text'], .cl-description textarea, .cl-cancelpol textarea, .cl-field select {
  background-color: #fff;
  border: 1px solid #d6d9dc;
  border-radius: 3px;
  width: 100%;
  padding: 7px;
  font-size: 14px;
  color: #888888;
  resize: none;
}

.cl-field select {
  padding: 0 10px;
  text-align: left;
  height: 37px;
}

.cl-available-spots {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
  width: 250px
}

.cl-description textarea {
  min-height: 150px;
}

.cl-next-btn, .cl-back-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cl-next-btn img, .cl-back-btn img {
  height: 25px;
  width: 25px;
}

.cl-upload-img {
  margin: 40px 0;
}

.image-drop-location {
  padding: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-drop-location input[type='file']{
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.cl-img-area {
  border: 1px dashed #c4c4c4;
  border-radius: 3px;
  height: 30vh;
  margin-top: 10px;
  margin-bottom: 10px 20px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cl-map-area {
  border-radius: 3px;
  height: 50vh;
  margin-top: 10px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cl-map-address-input { 
  background-color: #fff;
  border: 1px solid #d6d9dc;
  border-radius: 3px;
  width: 100%;
  padding: 7px;
  font-size: 14px;
  color: #888888;
  resize: none;
  margin-bottom: 10px;

}

.cl-map-container {
  height: 50vh;
	width: 100%;
  position: relative;
  margin-bottom: 50px;
}

.cl-step2-content {
  margin: 0px;
}

.cl-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 25px;
  padding-bottom: 50px
}

.preview-bar {
  color: #fff;
  background-color: rgb(0, 0, 0, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px 10px 50px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.preview-edit-btn, .preview-publish-btn {
  width: 125px;
  margin: 10px 10px 10px 10px;
}

.svg-btn {
  background-color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 20px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.svg-btn:hover {
  background-color: #e14658;
  cursor: pointer;
}

.svg-btn:focus {
  outline: none;
}

.minus {
  background-image: url('../images/minus.svg');
}

.minus:hover {
  background-image: url('../images/minus-hover.svg');
}

.add {
  background-image: url('../images/add.svg');
}

.add:hover {
  background-color: #e14658;
  background-image: url('../images/add-hover.svg');
}

.accept {
  background-image: url('../images/accept.svg');
}

.accept:hover {
  background-color: #e14658;
  background-image: url('../images/accept-hover.svg');
}

.decline {
  background-image: url('../images/decline.svg');
}

.decline:hover {
  background-color: #e14658;
  background-image: url('../images/decline-hover.svg');
}


.cl-confirmation-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.cl-confirmation-area{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cl-confirmation-header {
  font-size: 26px;
  text-align: center;
}

.cl-confirmation-body {
  margin: 20px;
  font-size: 16px;
}

/*[MY TRIPS]*/

.mt-page {
  Display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 16px;
  margin: 0 auto;
}

.mt-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
}


.mt-header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #40464B;
  font-weight: 500px;
  border-bottom: 1px solid #c4c4c4;
  padding: 10px 0;
}

.mt-trips-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.mt-content, .mt-memories-content {
  Display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  margin: 25px 10px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 1px #FFFFFF inset, 0 1px 3px rgba(34, 25, 25, 0.4);
}

.mt-content:hover  {
  box-shadow: 10px 10px 30px #c4c4c4;
  -webkit-transition: 0.3s;
	-o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.mt-img-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.mt-img-container img, .mt-avatar-img img {
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.mt-avatar-img img {
  border-radius: 50%;
}

.mt-content:hover .mt-img-container img {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
      transform: scale(1.1);
}

.mt-event-label {
  position: absolute;
  top: 20px;
  left: 20px;
  background: #6b7A8f;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 500px;
}

.mt-organizer-container {
  height: 100%;
  border-bottom: 1px solid #c4c4c4;
  padding: 0 10px;
}

.mt-trip-organizer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.mt-avatar-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 10px;
  width: 95%;
}

.mt-avatar-details {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin-left: 10px;
  font-size: 14px;
}

.mt-avatar-img {
  display: flex;
  flex: initial;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.mt-trip-title {
  font-size: 18px;
  font-weight: bold;
  margin: 5px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #40464B;
}

.mt-avatar-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #40464B;
}

.mt-avatar-location {
  font-size: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #888888;
}

.mt-trip-details {
  height: 100%;
  padding: 10px;
}

.mt-details-row {
  color: #888888;
  font-size: 13px;
  margin: 5px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mt-blank-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
  top: 0;
}

.mt-blank-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #54596e;
  font-weight: 500;
  width: 200px;
  text-align: center;
}

.mt-blank-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mt-blank-page-btn {
  width: 150px;
  margin: 10px;
}

.mt-blank-header img {
  width: 150px;
}

.mt-feedback-row {
  display: flex;
  justify-content: center;
}


/*[REVIEW]*/

.review-page {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 900px;
}

.review-sidebar {
  display: flex;
  flex-direction: column;
  flex: initial;
  width: 300px;
  margin: 20px;
}

.review-comments-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin: 20px;
}

.review-trip-details {
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
}

.review-trip-details img {
  width: 100%;
  height: 150px;
  overflow: hidden;
  object-fit: cover;
}

.review-title {
  font-size: 18px;
  line-height: 1;
  margin: 20px 10px;
  height: 20px;
  font-weight: 500px;
  color: #40464B;
}

.review-date-row {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  color: #40464B;
}

.review-date-section {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding: 10px 0;
  border-top: 1px solid #c4c4c4;
}

.review-guest-list {
  display: flex;
  flex-direction: column;
}

.review-guest-list {
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
  margin-top: 20px;
}

.review-guest-item {
  height: 110px;
  border: 1px solid #c4c4c4;
}

.review-avatar-area {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.review-avatar-area img {
  width: 50px;
  height: 50px;
  flex: initial;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.review-avatar-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
}

.review-avatar-name {
  Color: #40464B;
}

.review-avatar-location {
  Color: #888888;
}
.review-guest-row {
  display: flex;
  justify-content: center;
}

.comment-btn {
  font-size: 10px;
  padding: 5px;
  width: 100px;
}

.review-no-guest-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.review-no-comment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  border: 1px solid #c4c4c4;
}


.review-comment-row {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 10px;
}

.review-comment-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
}

.review-comment-area {
  margin: 10px 20px;
}

.review-post-row {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-bottom: 10px;
}


/*[PROFILE]*/

.profile-page {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
  margin-top: 40px;
}

.profile-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 325px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #c4c4c4;
  border-radius: 15px;
  margin: 0 10px;
  margin-bottom: 10px;
  padding: 30px 0;
}

.profile-section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-img-container {
  display: flex;
  align-items: center;
  height: 170px;
}

.profile-img {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  overflow: hidden;
  object-fit: cover;
}

.profile-name, .profile-location, .profile-vouch {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.profile-name, .profile-location, .profile-description {
  width: 100%;
}

.profile-name {
  color: #40464B;
  height: 50px;
  font-size: 30px;
  font-weight: 600px;
}

.profile-location {
  color: #626568;
  font-size: 18px;
  font-weight: 400px;
}

.profile-description {
  padding: 10px;
  margin: 10px;
  text-align: center;
  overflow-wrap: break-word;
  white-space: pre-line;
}

.profile-vouch, .mutual-vouch, .profile-vouch-active {
  color: #40464B;
  margin: 0px;
  text-align: center;
}

.profile-vouch-active {
  color: #fff;
}
.profile-btn-container {
  display: flex;
  flex-direction: column;
}

.edit-profile-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  color: #888888;
}

.edit-profile-btn:hover {
  font-weight: 700px;
  text-decoration: underline;
}

.social {
  Display: flex;
  flex-direction: column;
  margin-top: 20px
}

.social-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.social-row img {
  width: 20px;
  height: 20px;
  margin: 10px
}

.social-item {
  margin-right: 10px
}

.profile-review-container {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 0 30px;
}

.profile-review-header {
  font-size: 22px;
  margin: 10px 0 20px 0;
}

.profile-review-item {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
}

.profile-review-row {
  display: flex;
  justify-content: space-between;
}

.profile-avatar-area {
  display: flex;
  flex-direction: row;
}

.profile-avatar-area img{
  width: 50px;
  height: 50px;
  flex: initial;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.profile-avatar-details {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 10px;
  width: 150px;
}

.profile-avatar-name {
  width: 100%;
  font-weight: 400px;
  margin-bottom: 2px;
  font-size: 14px;
  color: #40464B;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-avatar-location {
  width: 100%;
  font-size: 95%;
  color: #40464B;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-review-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-review-timestamp, .profile-review-event {
  text-align: right;
  width: 100px;
}

.profile-review-content {
  margin: 10px;
}

.profile-review-area {
  margin: 10px;
}

/*[SETTINGS]*/

.se-page {
  display: flex;
  justify-content: flex-start;
  width: 90%;
  max-width: 900px;
  margin: 50px auto;
  flex-wrap: wrap;
}

.se-navigation-section {
  display: flex;
  flex-direction: column;
  width: 250px;
  margin-right: 50px;
  margin-bottom: 20px;
  display: flex;
}

.se-navigation-row {
  margin: 10px 20px;
  font-size: 15px;
  color: #888888;
}

.se-content-section {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.se-content-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
  margin-bottom: 50px;
}

.se-success {
  color: green;
  text-align: center;
  margin: 10px;
}


.se-content-header {
  display: flex;
  align-items: center;
  font-size: 15px;
  height: 30px;
  border-bottom: 1px solid #c4c4c4;
  background-color: #fafaff;
  color: #40464B;
  padding: 0 20px;
}

.se-content {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  font-size: 14px;
  padding: 0 20px;
}

.se-content-subheader {
  margin: 15px 0;
}

.se-profile-img {
  position: relative;
  display: flex;
  height: 200px;
  width: 200px;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 10px 0;
}

.se-profile-img img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  border: 1px solid #c4c4c4;
}

.se-edit-profile-img {
  position: absolute;
  bottom: 0;
}

.se-content input[type='file']{
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.se-content-row {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.se-payout-row {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.se-payout-title {
  width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}

.se-payout-content {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.se-payout-details {
  display: flex;
  width: 50px;
  text-align: center;
  align-items: flex-end;
  justify-content: center;
}

.se-content-row label {
  display: flex;
  flex-wrap: wrap;
  width: 150px;
  align-items: center;
  padding-right: 10px;
  margin: 0px
}

.se-content-row input[type='text'], .se-content-row input[type='password'], .se-content-row textarea ,.se-content-row select, .review-comment-area textarea {
  background-color: #fff;
  border: 1px solid #d6d9dc;
  border-radius: 7px;
  width: 100%;
  padding: 5px 10px;
  color: #888888;
  resize: none;
}

.se-content-row textarea {
  height: 200px
}

.se-btn-row {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
}

.se-birthday-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.se-birthday-month {
  position: relative;
  width: 45%;
}

.se-birthday-day {
  position: relative;
  width: 25%;
  margin-left: 15px;
}

.se-birthday-year {
  position: relative;
  width: 30%;
  margin-left: 15px;
}

.se-select-icon {
  top: 10%;
}

.se-phone-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.se-phone-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 15px;
  height: 30px;
  align-items: center;
  padding-right: 20px;
}

.se-notification-row input[type='checkbox'] {
  width: 15px;
  height: 15px;
  border: 1px solid #c4c4c4;
  background-color: #fff;
  cursor: pointer;
  margin-right: 20px;
  flex: initial;
}

.se-notification-row input[type='checkbox']:checked {
  background-color: #FD6E6E;
}

.se-notification-row {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
  align-items: center;
}

.se-notification-row label {
  margin: 0;
  flex: 1;
}


/*[MESSAGES]*/

.msg-page {
  display: flex;
  max-width: 1000px;
  width: 100vw;
  margin: 0 auto;
  justify-content: center;
}

.msg-contacts-section {
  width: 100%;
  max-width: 640px;
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow: auto;
}

.msg-contacts-header-container {
  display: flex;
  flex-direction: column;
}


.msg-blank-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #54596e;
  font-weight: 500;
  width: 200px;
  text-align: center;
}

.msg-blank-header img {
  padding: 10px;
  width: 100px;
}

/*.msg-contacts-search-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    top: 100px;
    left: 100px;
    margin: 0px 10px 15px 10px;
    align-items: center;
    background-color: #e9e9e9;
    border-radius: 25px
  }
  
.msg-contacts-search-input {
  width: 100%;
  padding: 15px;
  align-items: center;
  background-color: transparent;
  border: none;
}

.msg-contacts-search-input:focus {
  outline: none;
  box-shadow: none;
}*/

.msg-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
}

.msg-user-dropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.msg-contacts {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px;
}
.msg-contacts img {
  width: 50px;
  height: 50px;
  flex: initial;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  margin-right: 10px;
}

.msg-contacts-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.msg-contacts-details-name {
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  color: #40464B;
}


.msg-contacts-details-preview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.msg-contacts-details-timestamp {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #40464B;
  padding: 20px
}

.msg-contacts-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
}
.msg-contacts-row:hover {
  cursor: pointer;
  background-color: #F2F5FF
}

.msg-chat-header, .msg-contacts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #c4c4c4;
}

.msg-chat-header {
  justify-content: flex-start;
}

.msg-header-item {
  font-size: 18px;
  color: #40464B;
  margin: 0 10px;
  font-weight: 700;
}

.msg-contacts-header img, .msg-chat-header img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #c4c4c4;
  cursor: pointer;
}

.msg-chat-header img {
  border: none;
}

.msg-header-placeholder{
  width: 35px;
}

.msg-contact-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px 20px;
  border-bottom: 1px solid #c4c4c4;
}

.msg-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: initial;
}

.msg-avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #c4c4c4;
}

.msg-avatar-details {
  width: 100px;
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  flex: 1;
}

.msg-avatar-name {
  font-size: 14px;
  color: #40464B;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.msg-preview {
  font-size: 13px;
  color: #888888;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.msg-contacts-timestamp {
  font-size: 14px;
  color: #888888;
  flex: initial;
}

.msg-chat-timestamp {
  font-size: 12px;
  color: #888888;
  margin: 0 auto;
  margin-top: 10px;
}

.msg-chat-section {
  width: 100vw; 
  display: flex; /*TEMP*/
  flex-direction: column;
  height: 90vh;
}

.msg-chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid #c4c4c4;
  overflow: auto;
}

.msg-type-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.msg-icon-area {
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;

}

.msg-icon-area img {
  width: 20px;
  height: 20px;
  flex: initial;
}

.msg-type-area {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  margin: 0 10px;
  align-items: center;
  background-color: #e9e9e9;
  border-radius: 25px;
}

.msg-type-textarea {
  display: flex;
  justify-content: space-between;
  width: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  padding-left: 10px;
  height: 45px;
}

.msg-type-textarea:focus {
  outline: none;
  box-shadow: none;
}

.msg-received-chat-container, .msg-sent-chat-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin: 0 10px;
}

.msg-sent-chat-container{
  justify-content: flex-end;
}

.msg-chat-avatar {
  display: flex;
  align-items: flex-end;
}
.msg-chat-avatar img{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.msg-received-chat-area {
  color: #40464B;
  margin-left: 10px;
  background-color: #e9e9e9;
  padding: 10px;
  border-radius: 15px 15px 15px 5px;
  margin-top: 10px;
  font-size: 13px;
  max-width: 250px;
  overflow-wrap: break-word;
}

.msg-sent-chat-area{
  color: #fff;
  margin-right: 10px;
  background-color: #0089ff;
  padding: 10px;
  border-radius: 15px 15px 5px 15px;
  margin-top: 10px;
  font-size: 13px;
  max-width: 250px;
  overflow-wrap: break-word;
}

/*[PAYMENT]*/

.pmt-field, .od-field {
  display: flex;
  justify-content: flex-end;
  width: 25%;
}

.pmt-field [type='text'], .od-field [type='text']{
  text-align: right;
  background-color: #fff;
  border: 1px solid #d6d9dc;
  border-radius: 3px;
  width: 100%;
  padding: 2px 5px;
  font-size: 13px;
  color: #888888;
  resize: none;
}

.pmt-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.pmt-cvc-exp-container {
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.pmt-container label, .pmt-cvc-exp-container label {
  font-size: 15px;
  margin-bottom: 10px;
}

.pmt-card-graphics-container img {
  width: 35px;
  margin: 5px;
}

.pmt-cvc-exp-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.pmt-info {
  padding: 15px;
  background-color:#fff;
  border: 1px solid #d6d9dc;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #888888;
  resize: none;
}

.pmt-info-focus {
  outline: none;
  box-shadow: 0 0 5px #51CBEE;
}

.pmt-info-error {
  outline: none;
  box-shadow: 0 0 5px red;
}

.pmt-btn {
  margin: 20px;
  display: flex;
  justify-content: center;
}

.pmt-graphic {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
}

.pmt-graphic img {
  width: 100%;
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {

  .ex-listing {
    width: 100%;
    flex-direction: column;
  }

  .ex-map {
    display: none;
  }

  .ex-listing-date, .ex-listing-price {
    font-size: 12px;
  }

  .ex-listing-img {
    display: none;
  }


  .ex-listing-content {
    width: 100%;
  }

  .profile-name, .profile-location {
    width: 100%;
  }

 .nav-full {
   display: none;
 }


.se-navigation-section {
  display: none;
}

}


/* Tablet-1 */
@media only screen and (min-width: 401px) and (max-width: 640px) {

  .ex-map {
    display: none;
  }

  .ex-listing {
    width: 100%
  }

  .ex-listing-img {
    display: none
  }

  .ex-listing-content {
    width: 100%
  }

 .nav-full {
  display: none;
}

}

/* Tablet-2 */
@media only screen and (min-width: 641px) and (max-width: 960px) {
    
  .ex-map {
    display: none;
  }

  .ex-listing {
    width: 100%
  }

  .ld-img {
    width: 50%
  }

  .dash-subheader {
    font-size: 18px;
  }

  .dash-sidebar {
    max-width: 600px;
  } 

  .cl-step2-content {
    margin: 0 auto;
    width: 500px;
  }

  .se-content-row {
    flex-direction: row;
  }

  .nav-mobile {
    display: none;
  }

  .msg-chat-section, .msg-contacts-section {
    display: flex;
    border-left: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
  }

  .msg-chat-section {
    width: 100%;
    min-width: 450px;
    border-right: 1px solid #c4c4c4;
  }

  .msg-chat-header img {
    display: none;
  }

  .msg-contacts-section {
    max-width: 400px;
  }

  .msg-new {
    font-weight: 700px
  }
}



/* Desktop Styles */
@media only screen and (min-width: 961px) {


  .ld-img {
    width: 50%
  }

  .ld-page, .od-page {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
  }

  .dash-page, .od-page {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .od-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 575px;
  }

  .ld-sidebar, .dash-sidebar {
    width: 35%;
  }
  
  
  .cl-header {
    display: flex;
    flex-direction: row;
  }

  .cl-step2-content {
    margin: 0 auto;
    width: 500px;
  }

  .se-content-section {
    width: 65%;
  }

  .se-content-row {
    flex-direction: row;
  }

  .nav-mobile {
    display: none;
  }

  .why-share-accommodations-section {
    height: 120vh
  }

  .msg-chat-section, .msg-contacts-section {
    display: flex;
    border-left: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
  }

  .msg-chat-section {
    width: 100%;
    min-width: 600px;
    border-right: 1px solid #c4c4c4;
  }

  .msg-chat-header img {
    display: none;
  }

  .msg-contacts-section {
    max-width: 400px;
  }

  .pmt-btn {
    display: flex;
    justify-content: flex-start;
  }

}

/* [ Dropzone ] */ 

.dz-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  margin-top: 10px;
}

.dz-drop-area {
  text-align: center;
  padding: 20px;
}
.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16;
  
}

.thumb {
  display: inline-flex;
  border-radius: 2;
  border: 1px solid #eaeaea;
  margin-bottom: 8;
  margin-right: 8;
  width: 100;
  height: 100;
  padding: 4;
  box-sizing: border-box;
}

.thumbInner { 
  display: flex;
  min-width: 0;
  /* overflow: hidden; */
}

.img {
  display: block;
  width: auto;
  height: 20%
}

/* [ ANIMATIONS ] */

.fadein{ 
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 1s; /* Firefox < 16 */
      -ms-animation: fadein 1s; /* Internet Explorer */
       -o-animation: fadein 1s; /* Opera < 12.1 */
           animation: fadein 1s;
}

@keyframes fadein {
  from { opacity: 0;
        -webkit-transform: translateY(10px);
        transform: translateY(10px); }
  to   { opacity: 1; 
        -webkit-transform: translateY(0);
        transform: translateY(0)}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0;
        -webkit-transform: translateY(10px);
        transform: translateY(10px); }
  to   { opacity: 1; 
        -webkit-transform: translateY(0);
        transform: translateY(0)}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0;
        -webkit-transform: translateY(10px);
        transform: translateY(10px); }
  to   { opacity: 1; 
        -webkit-transform: translateY(0);
        transform: translateY(0)}
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0;
        -webkit-transform: translateY(10px);
        transform: translateY(10px); }
  to   { opacity: 1; 
        -webkit-transform: translateY(0);
        transform: translateY(0)}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0;
        -webkit-transform: translateY(10px);
        transform: translateY(10px); }
  to   { opacity: 1; 
        -webkit-transform: translateY(0);
        transform: translateY(0)}
}


/*[404]*/

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100vh;
}

.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 90%;
  opacity: 0.75;
}

.error-container img {
  width: 300px;
}

.error-header {
  font-size: 80px;
  color: #54596e;
  font-weight: 600;
}

.error-text {
  font-size: 20px;
  text-align: center;
  line-height: 1.3;
}

.error-btn {
  margin-top: 20px;
}


/*[NOTIFICATIONS]*/

.noti-page {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 365px;
}


.noti-header{
  display: flex;
  justify-content: center;
  width: 300px;
  border-bottom: 1px solid #c4c4c4;
  padding: 20px;
}

.noti-header img {
  width: 200px;
}

.noti-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  border-bottom: 1px solid #c4c4c4;
  padding: 20px;
}

.noti-title {
  color: #54596e;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.noti-content img{
  width: 100px;
}

.noti-subtitle{
  margin-bottom: 20px;
  color: #888888;
  text-align: center;
}

.noti-footer{
  margin-top: 10px;
  color: #888888;
}
